import { AppEnvironment } from '../app/core/models/app-environment';
import { version } from '../../package.json'

export const environment: AppEnvironment = {
  production: true,
  version,
  apiUrl: 'https://api.absdepot.com',
  googleMapsApiKey: 'AIzaSyCITbgbBEQv6dIp7fHfMdp5vfNxw4v1TeI',
  cardnet: {
    key: 'zKx2RnrvnCZfugLcJPzMj2_I56ONV7HQ',
    env: 'https://servicios.cardnet.com.do/servicios/tokens',
  },
  firebase: {
    projectId: 'abs-depot-9cb2c',
    appId: '1:988504934817:web:b0fbef55940a5f49a323e0',
    storageBucket: 'abs-depot-9cb2c.appspot.com',
    apiKey: 'AIzaSyB3emeZ7Mpt5rvUsr11pMdR4VhEBWw6uV0',
    authDomain: 'app.absdepot.com',
    messagingSenderId: '988504934817',
  },
  sentry: {
    dsn: 'https://50a64e7a952bfe7ded6001b7bad84669@o4508088242012160.ingest.us.sentry.io/4508287036686336',
  }
};
