{
  "name": "absdepot",
  "version": "3.0.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build && npm run sentry:sourcemaps",
    "test": "ng test",
    "lint": "ng lint",
    "appflow:build": "ng build --configuration=$CONFIG",
    "version": "node scripts/sync-versions.js && git add -A",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org absdepot --project customer-app ./www/browser && sentry-cli sourcemaps upload --org absdepot --project customer-app ./www/browser"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.0.3",
    "@angular/cdk": "^19.0.2",
    "@angular/common": "^19.0.3",
    "@angular/core": "^19.0.3",
    "@angular/fire": "^18.0.1",
    "@angular/forms": "^19.0.3",
    "@angular/google-maps": "^19.0.2",
    "@angular/platform-browser": "^19.0.3",
    "@angular/platform-browser-dynamic": "^19.0.3",
    "@angular/router": "^19.0.3",
    "@awesome-cordova-plugins/core": "^6.4.0",
    "@capacitor-firebase/authentication": "^6.2.0",
    "@capacitor/android": "6.2.0",
    "@capacitor/app": "^6.0.0",
    "@capacitor/browser": "^6.0.0",
    "@capacitor/camera": "^6.0.0",
    "@capacitor/core": "^6.0.0",
    "@capacitor/device": "^6.0.0",
    "@capacitor/geolocation": "^6.0.0",
    "@capacitor/haptics": "^6.0.0",
    "@capacitor/ios": "6.2.0",
    "@capacitor/keyboard": "^6.0.0",
    "@capacitor/preferences": "^6.0.0",
    "@capacitor/push-notifications": "^6.0.0",
    "@capacitor/share": "^6.0.0",
    "@capacitor/splash-screen": "^6.0.0",
    "@capacitor/status-bar": "^6.0.0",
    "@ionic/angular": "8.4.1",
    "@maskito/angular": "^1.9.0",
    "@maskito/core": "^1.9.0",
    "@sentry/angular": "8.42.0",
    "@sentry/cli": "2.39.1",
    "@types/googlemaps": "^3.43.3",
    "date-fns": "^3.0.6",
    "firebase": "^10.14.1",
    "ngxtension": "^4.0.0",
    "rxjs": "^7.8.1",
    "swiper": "^11.0.5",
    "tslib": "^2.0.0",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.4",
    "@angular/cli": "^19.0.4",
    "@angular/compiler": "^19.0.3",
    "@angular/compiler-cli": "^19.0.3",
    "@angular/language-service": "^19.0.3",
    "@capacitor/assets": "^3.0.4",
    "@capacitor/cli": "6.2.0",
    "@ionic/angular-toolkit": "12.1.1",
    "@types/geojson": "^7946.0.14",
    "@types/google.maps": "^3.55.12",
    "@types/jasmine": "^5.1.5",
    "@types/node": "^12.20.55",
    "angular-eslint": "19.0.1",
    "autoprefixer": "^10.4.19",
    "eslint": "^9.15.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.39",
    "prettier": "^3.3.3",
    "prettier-plugin-tailwindcss": "^0.6.9",
    "tailwindcss": "^3.4.6",
    "ts-node": "~8.3.0",
    "typescript": "5.6.3",
    "typescript-eslint": "8.16.0"
  },
  "browserslist": [
    "Chrome >=79",
    "ChromeAndroid >=79",
    "Firefox >=70",
    "Edge >=79",
    "Safari >=14",
    "iOS >=14"
  ],
  "overrides": {
    "@angular/fire": {
      "@angular/core": "$@angular/core",
      "@angular/common": "$@angular/common",
      "@angular/platform-browser": "$@angular/platform-browser",
      "@angular/platform-browser-dynamic": "$@angular/platform-browser-dynamic"
    }
  }
}
